<template>
  <div class="add">
    <el-dialog
      :title="showStatus == 1 ? '添加渠道': '编辑渠道'"
      :visible="!!showStatus"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        class="form"
        hide-required-asterisk
      >
        <el-form-item label="渠道名称" prop="name" v-show="showStatus === 1">
          <el-input v-model.trim="form.name" placeholder="请输入渠道名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="渠道账号" prop="account">
            <el-input v-model.trim="form.account" placeholder="请输入渠道账号"></el-input>
        </el-form-item>-->
        <!-- <el-form-item :label="isEdit ? '修改密码' : '输入密码'" prop="psd">
          <el-input v-model.trim="form.psd" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repsd">
          <el-input v-model.trim="form.repsd" placeholder="请确认密码" show-password></el-input>
        </el-form-item>-->
        <el-form-item label="当前状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态" style="width:100%">
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道商" prop="manageId">
          <el-select v-model="form.manageId" placeholder="请选择渠道商" style="width:100%">
            <el-option
              v-for="item in customers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服" prop="auditIds">
          <el-select v-model="form.auditIds" multiple placeholder="请选择客服" style="width:100%">
            <el-option
              v-for="item in customers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api';
export default {
  props: {
    // 是否展示弹框
    showStatus: {
      type: Number,
      default: 0
    },
    // 被编辑数据
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    // var checkPsd = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'));
    //   } else {
    //     if (this.form.repsd !== '') {
    //       this.$refs.form.validateField('repsd');
    //     }
    //     callback();
    //   }
    // };
    // var checkRepsd = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请再次输入密码'));
    //   } else if (value !== this.form.psd) {
    //     callback(new Error('两次输入密码不一致!'));
    //   } else {
    //     callback();
    //   }
    // };
    var checkAuditor = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择客服'));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: '',
        // account: '',
        // psd: '',
        // repsd: '',
        status: null,
        manageId: null,
        auditIds: []
      },
      statusOpts: [
        { value: 0, label: '开启' },
        { value: 2, label: '禁用' }
      ],
      rules: {
        name: [{ required: true, message: '请输入链接名称', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        // account: [
        //   { required: true, message: '请输入链接账号', trigger: 'blur' },
        //   {
        //     pattern: /^[a-zA-z]\w{3,9}$/,
        //     message: '账号应为4-10位字符，可使用数字、字母、下划线，且以字母开头',
        //     trigger: 'blur'
        //   }
        // ],
        // psd: [
        //   { validator: checkPsd, trigger: ' blur' },
        //   {
        //     pattern: /^[a-zA-z0-9]\w{5,19}$/,
        //     message: '6-20位字符，由数字、字母、下划线组成，且不能以下划线开头',
        //     trigger: 'blur'
        //   }
        // ],
        // repsd: [{ validator: checkRepsd, trigger: 'blur' }],
        manageId: [{ required: true, message: '请选择绑定的渠道商', trigger: 'change' }],
        auditIds: [{ required: true, message: '请选择绑定的客服', trigger: 'change', validator: checkAuditor}]
      },
      customers: [],
      width: '40%'
    };
  },
  methods: {
    // 获取客服,渠道商选项
    getCustomers() {
      return service.getChild({ pageNum: 0, pageSize: -1 }).then(res => {
        let { list = [] } = res;
        this.customers = list.reduce((prev, cur) => (prev.push({ value: cur.id, label: cur.username }), prev), []);
      });
    },
    cancel() {
      this.$refs['form'].resetFields();
      this.$emit('cancel');
    },
    submit() {
      let check = true;
      let { showStatus, form } = this;
      // 添加，全校验
      showStatus === 1 && this.$refs['form'].validate(res => (check = res));

      // else if ((isEdit && form.psd) || (isEdit && form.repsd)) {
      //   // 编辑且密码不为空，校验密码
      if (check)
        this.$refs['form'].validateField(['auditIds'], err => (check = !err));
      // }
      if (!check) return;
      this.$emit('submit-form', form);
      this.$refs['form'].resetFields();
    },
    openDialog() {
      if(document.documentElement.clientWidth < 480){
        this.width = '80%'
      }
      let { showStatus, editData = {}, form = {} } = this;
      if (showStatus === 2) {
        this.$nextTick(() => {
          for (let key in form) {
            if(key === 'auditIds'){
              form[key] = key in editData ? editData[key].split(",").filter(s => s.trim().length > 0).map(s => Number.parseInt(s) || s): [];
            }else{
              form[key] = key in editData ? editData[key] : [];
            }
          }
        });
      }
    }
  },
  created() {
    this.getCustomers();
  }
};
</script>

<style lang="scss" scoped>
.add {
  .form {
    width: 80%;
    text-align: left;
    margin: 0 auto;
  }
}
</style>
