import API from "../../../api/index";

export default {
  getLinkList: params => {
    return API.requestGet({
      url: "link/getLink",
      params,
      auth: true
    });
  },
  add: params => {
    return API.requestPost({
      url: "link/addLink",
      data: params,
      auth: true
    });
  },
  edit: params => {
    return API.requestPost({
      url: "link/ediLink",
      data: params
    });
  },
  remove: params => {
    return API.requestPost({
      url: "link/delLink",
      data: params
    });
  },
  // 查询子账号
  getChild: params => {
    return API.requestGet({
      params,
      url: "son/getList"
    });
  }
};
