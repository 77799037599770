<template>
  <div class="user-box">
    <!-- 顶部搜索栏 -->
    <search-form @search="search" @show-add="showStatus = 1"></search-form>
    <!-- 链接表 -->
    <link-table :list="links" @edit="handleEdit" @delete="handleDel"></link-table>
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <!-- 添加链接 -->
    <add-form
      :show-status="showStatus"
      :edit-data="editData"
      @cancel="showStatus = 0"
      @submit-form="submit"
    ></add-form>
  </div>
</template>

<script>
// components
import searchForm from './components/search-form';
import linkTable from './components/link-table';
import addForm from './components/add-form';

// api
import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      links: [],
      page: {},
      showStatus: 0, // 弹框展示状态 1 => 添加，2 => 编辑
      editData: {},
      searchData: {}
    };
  },
  components: {
    searchForm,
    linkTable,
    addForm
  },
  methods: {
    init() {
      let { pageSize = 10 } = this.page;
      this.getList({ pageNum: 1, pageSize });
    },
    // 获取连接列表
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getLinkList(params).then(res => {
        let { page = {}, list = [] } = res;
        this.links = list.map((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          return item;
        });
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    },
    // 编辑链接
    handleEdit(row) {
      let { isUpdate = true } = row;
      if (!isUpdate) {
        this.$notify({
          message: `渠道商【${row.manageName}】已被禁用，暂不可编辑！`,
          type: 'error',
          offset: 400,
          duration: 1500
        });
        return;
      }
      this.showStatus = 2;
      this.editData = row;
    },
    // 提交表单
    submit(data) {
      let { showStatus, editData = {} } = this;
      showStatus === 2
        ? this.handleSubmit(service.edit({ id: editData.id, name:data.name, status:data.status,manageId:data.manageId,auditIds: data.auditIds.join(',') }), { pageNum: this.currentPage })
        : this.handleSubmit(service.add({name:data.name, status:data.status, manageId:data.manageId, auditId: data.auditIds.join(',')}), { pageNum: 1 });
    },
    // 提交表单统一处理
    handleSubmit(r, refreshParams) {
      return r.then(() => {
        this.showStatus = 0;
        this.$notify({ type: 'success', message: '提交成功！', offset: 400 });
        this.getList(refreshParams);
      });
    },
    // 删除链接
    handleDel(row) {
      this.$alert('是否将此链接永久性删除？', '删除链接', { showCancelButton: true })
        .then(() => {
          return service.remove({ linkId: row.id || '' }).then(() => {
            this.$notify({ type: 'success', message: '删除成功！', offset: 400 });
            this.init();
          });
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.user-box {
  width: 100%;
  .change {
    width: 100%;
  }
  .tool-box {
    overflow: hidden;
    text-align: left;
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
  }
}
</style>
